export function calculateRatingPercents(ratingsCount) {
  if (!ratingsCount) {
    return null;
  }
  if (!ratingsCount.totalReviews) {
    return ratingsCount;
  }

  const { star1, star2, star3, star4, star5 } = ratingsCount.ratingAggregation;
  const { totalReviews } = ratingsCount;
  const percents = [star1, star2, star3, star4, star5].map(item => (item / totalReviews) * 100);
  const truncated = percents.map(Math.trunc);
  const fraction = percents.map((item, i) => item - truncated[i]);
  const fractionSorted = fraction.concat().sort((a, b) => b - a);
  const summ = truncated.reduce((a, b) => a + b);
  for (let i = 0, j = 100 - summ; i < j; i += 1) {
    const index = fraction.indexOf(fractionSorted[i]);
    truncated[index] += 1;
  }
  return {
    ...ratingsCount,
    percents: truncated.reverse(),
  };
}
