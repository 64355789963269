const INFO_FIELDS = [
  'userRating',
  'title',
  'text',
  'bodytype',
  'styleId',
  'age',
  'height',
  'weight',
  'kidsUnderTwelve',
  'milesPerYear',
  'drivingTerrain',
];
const ADDITIONAL_RATINGS_FIELDS = [
  'performanceRating',
  'comfortRating',
  'interiorRating',
  'safetyRating',
  'technologyRating',
  'reliabilityRating',
  'valueRating',
];

export function transformWriteReviewData({ reviewId, ...additionalData }) {
  const result = {
    id: reviewId,
  };

  INFO_FIELDS.forEach(fieldName => {
    if (additionalData[fieldName]) {
      result[fieldName] = additionalData[fieldName];
    }
  });

  ADDITIONAL_RATINGS_FIELDS.forEach(fieldName => {
    if (additionalData[fieldName]) {
      result[`${fieldName}Dto`] = { [fieldName]: additionalData[fieldName] };
    }
  });

  return result;
}
