import { Storage } from 'site-modules/shared/utils/storage';

export const STORAGE_PATH = 'ratedConsumerReviews';

export const ThumbsStorage = {
  /**
   * Returns local storage instance
   * @returns {Storage}
   */
  getLocalStorage() {
    if (!this.localStorage) this.localStorage = new Storage('localStorage');
    return this.localStorage;
  },

  /**
   * Checks if review is already rated
   * @param id
   */
  isReviewRated(id) {
    const storage = this.getLocalStorage().get(STORAGE_PATH) || [];
    return storage.includes(id);
  },

  /**
   * Adds a review id to array of rated
   * @param id
   */
  markReviewRated(id) {
    const storage = this.getLocalStorage().get(STORAGE_PATH) || [];
    if (storage.includes(id)) {
      return;
    }

    storage.push(id);
    this.getLocalStorage().set(STORAGE_PATH, storage);
  },
};
